import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { boolean, number, object, ref } from 'yup'
import { ConfigureCreditSettingsMessages } from '../../messages'
import { CreditSettingsFormValues } from '../../models'

export const useCreditSettingsFormValidation = (): ObjectSchema<CreditSettingsFormValues> => {
  const intl = useIntl()

  const validationSchema = useMemo(
    () =>
      object({
        initialCredit: number()
          .typeError(intl.formatMessage(ConfigureCreditSettingsMessages.creditAmountRequiredMessage))
          .required(intl.formatMessage(ConfigureCreditSettingsMessages.creditAmountRequiredMessage))
          .min(5, intl.formatMessage(ConfigureCreditSettingsMessages.customAmountMinMessage))
          .max(1000, intl.formatMessage(ConfigureCreditSettingsMessages.customAmountMaxMessage)),
        automaticRecharge: boolean(),
        automaticRechargeThreshold: number().when('automaticRecharge', {
          is: true,
          then: (schema) =>
            schema
              .required(intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText))
              .min(5, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText))
              .max(995, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText))
              .lessThan(
                ref('automaticRechargeAmount'),
                intl.formatMessage(
                  ConfigureCreditSettingsMessages.rechargeThresholdGreaterThanRechargeAmountErrorMessage,
                ),
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        automaticRechargeAmount: number().when('automaticRecharge', {
          is: true,
          then: (schema) =>
            schema
              .required(intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText))
              .min(10, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText))
              .max(1000, intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText)),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
    [intl],
  )

  return validationSchema
}
