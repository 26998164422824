import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectCreateOrganizationWizardListWithReservedNames } from '../../features/createOrganizationWizard/createOrganizationWizardSelector'
import { createCreateOrganizationWizard } from '../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { deleteOrganization } from '../../features/deleteOrganization/deleteOrganizationSlice'
import { selectOrganizationOnboardingWizardEnabled } from '../../features/featureFlags/featureFlagsSelectors'
import {
  acceptInvitation,
  declineInvitation,
  getOrganizationsPageData,
} from '../../features/myOrganizations/myOrganizationSlice'
import {
  selectMyOrganizationInvitationsList,
  selectMyOrganizationsList,
} from '../../features/myOrganizations/myOrganizationsSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import {
  selectRequestStatusIsPending,
  selectRequestStatusIsSucceeded,
} from '../../features/requestStatus/requestStatusSelectors'
import { organizationsPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { OrganizationsPage } from './OrganizationsPage'

export const ConnectedOrganizationsPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const isGetOrganizationsPageDataPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getOrganizationsPageData'),
  )
  const isDeleteOrganizationSuccessful = useAppSelector((state) =>
    selectRequestStatusIsSucceeded(state, 'deleteOrganization'),
  )
  const isDeleteOrganizationPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'deleteOrganization'),
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const myOrganizationList = useAppSelector(selectMyOrganizationsList)
  const myOrganizationInvitationsList = useAppSelector(selectMyOrganizationInvitationsList)
  const createOrganizationWizardList = useAppSelector((state) =>
    selectCreateOrganizationWizardListWithReservedNames(state.createOrganizationWizard),
  )
  const invitationOrganizationDisplayName = useCallback(
    (invitationId: string) => {
      return myOrganizationInvitationsList.find((member) => member.invitation.id === invitationId)?.invitation
        .organiationDisplayName
    },
    [myOrganizationInvitationsList],
  )
  const isOrganizationOnboardingWizardFeatureFlagEnabled = useAppSelector(selectOrganizationOnboardingWizardEnabled)

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onAcceptInvitation = useCallback(
    (invitationId: string) => {
      const organizationDisplayName = invitationOrganizationDisplayName(invitationId) as string
      dispatch(acceptInvitation({ invitationId, organizationDisplayName }))
    },
    [dispatch, invitationOrganizationDisplayName],
  )

  const onDeclineInvitation = useCallback(
    (invitationId: string) => {
      const organizationDisplayName = invitationOrganizationDisplayName(invitationId) as string
      dispatch(declineInvitation({ invitationId, organizationDisplayName }))
    },
    [dispatch, invitationOrganizationDisplayName],
  )

  const onCreateOrganizationWizard = useCallback(() => {
    dispatch(createCreateOrganizationWizard())
  }, [dispatch])

  const onDeleteOrganization = useCallback(
    (organizationName: string) => {
      dispatch(deleteOrganization({ organizationName }))
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(getOrganizationsPageData())
  }, [dispatch, isDeleteOrganizationSuccessful])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: organizationsPageRoutePath }))
  }, [dispatch])

  return isGetOrganizationsPageDataPending ? (
    <LoadingPage />
  ) : (
    <OrganizationsPage
      createOrganizationWizardList={createOrganizationWizardList}
      isDeleteOrganizationPending={isDeleteOrganizationPending}
      isDeleteOrganizationSuccessful={isDeleteOrganizationSuccessful}
      isLeftColumnOpen={isLeftColumnOpen}
      isOrganizationOnboardingWizardFeatureFlagEnabled={isOrganizationOnboardingWizardFeatureFlagEnabled}
      onAcceptInvitation={(invitationId) => onAcceptInvitation(invitationId)}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onCreateOrganizationWizard={onCreateOrganizationWizard}
      onDeclineInvitation={(invitationId) => onDeclineInvitation(invitationId)}
      onDeleteOrganization={(organizationName) => onDeleteOrganization(organizationName)}
      organizationInvitations={myOrganizationInvitationsList}
      organizations={myOrganizationList}
    />
  )
}
