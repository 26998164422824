import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../../../features/analytics/analyticsSlice'
import { selectCreateOrganizationWizard } from '../../../../features/createOrganizationWizard/createOrganizationWizardSelector'
import {
  editCreateOrganizationWizard,
  getCreateOrganizationWizard,
} from '../../../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectIsLeftColumnShowing } from '../../../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../../../features/navigationBar/navigationBarSlice'
import { selectRequestStatus } from '../../../../features/requestStatus/requestStatusSelectors'
import { CreateOrganizationWizardPath } from '../../../../routes/routePaths'
import { getCreateOrganizationWizardOrganizationInformationPagePath } from '../../../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { CreateOrganizationWizardPageSteps, CreditSettingsFormValues } from '../../models'
import { ConfigureCreditSettingsPage } from './ConfigureCreditSettingsPage'

export const ConnectedConfigureCreditSettingsPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isConfigureCreditSettingsPending = useAppSelector(
    (state) => selectRequestStatus(state, 'configureCreditSettings') === 'pending',
  )
  const isGetOrganizationWizardPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getCreateOrganizationWizard') === 'pending',
  )
  const createOrganizationWizard = useAppSelector((state) =>
    selectCreateOrganizationWizard(state, createOrganizationWizardId),
  )

  const handleConfigureCreditSettings = useCallback(
    (values: CreditSettingsFormValues) => {
      dispatch(
        editCreateOrganizationWizard({
          createOrganizationWizard: {
            id: createOrganizationWizardId,
            automaticRecharge: values.automaticRecharge,
            automaticRechargeAmount: values.automaticRechargeAmount,
            automaticRechargeThreshold: values.automaticRechargeThreshold,
            initialCredit: values.initialCredit,
          },
          currentRoute: CreateOrganizationWizardPageSteps.ConfigureCreditSettings,
        }),
      )
    },
    [dispatch, createOrganizationWizardId],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const creditSettingsDefaultValues = {
    initialCredit: createOrganizationWizard?.createOrganizationWizard?.initialCredit,
    automaticRechargeThreshold: createOrganizationWizard?.createOrganizationWizard?.automaticRechargeThreshold,
    automaticRechargeAmount: createOrganizationWizard?.createOrganizationWizard?.automaticRechargeAmount,
    automaticRecharge: createOrganizationWizard?.createOrganizationWizard?.automaticRecharge,
  }

  const onSkipToFreeTrial = useCallback(() => {
    dispatch(
      editCreateOrganizationWizard({
        createOrganizationWizard: {
          id: createOrganizationWizardId,
          initialCredit: 0,
        },
        currentRoute: CreateOrganizationWizardPageSteps.ConfigureCreditSettings,
      }),
    )
  }, [dispatch, createOrganizationWizardId])

  const onBack = useCallback(() => {
    navigate(getCreateOrganizationWizardOrganizationInformationPagePath(createOrganizationWizardId))
  }, [navigate, createOrganizationWizardId])

  useEffect(() => {
    dispatch(getCreateOrganizationWizard({ createOrganizationWizardId }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: CreateOrganizationWizardPath.ConfigureCreditSettings },
      }),
    )
  }, [dispatch, createOrganizationWizardId])

  return isGetOrganizationWizardPending ? (
    <LoadingPage />
  ) : (
    <ConfigureCreditSettingsPage
      creditSettingsDefaultValues={creditSettingsDefaultValues}
      isConfigureCreditSettingsPending={isConfigureCreditSettingsPending}
      isSkipToFreeTrialPending={isConfigureCreditSettingsPending}
      onBack={onBack}
      onConfigureCreditSettings={handleConfigureCreditSettings}
      onSkipToFreeTrial={onSkipToFreeTrial}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
    />
  )
}
