import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../../../../components/base'
import { Button } from '../../../../../../components/Button'
import { Checkbox } from '../../../../../../components/Checkbox'
import { RadioCards } from '../../../../../../components/forms/RadioCards'
import { TextField } from '../../../../../../components/TextField'
import { useFixedForm } from '../../../../../../hooks'
import { ConfigureCreditSettingsMessages } from '../../../../messages'
import { CreditSettingsFormValues } from '../../../../models'
import { useCreditSettingsFormValidation } from '../../useCreditSettingsFormValidation '

export interface ConfigureCreditSettingsProps {
  /** The default values for the credit settings form. */
  creditSettingsDefaultValues?: CreditSettingsFormValues
  /** The flag indicating that the request to configure credit settings is pending. */
  isConfigureCreditSettingsPending: boolean
  /** The flag indicating that the request to skip to the free trial page is pending. */
  isSkipToFreeTrialPending: boolean
  /** A callback to handle navigation (Back) */
  onBack: () => void
  /** A callback that when executed configures the credit settings */
  onConfigureCreditSettings: (values: CreditSettingsFormValues) => void
  /** A callback that when executed navigates to the free trial page. */
  onSkipToFreeTrial: () => void
}

export const ConfigureCreditSettings: FunctionComponent<ConfigureCreditSettingsProps> = ({
  creditSettingsDefaultValues,
  isConfigureCreditSettingsPending,
  isSkipToFreeTrialPending,
  onBack,
  onConfigureCreditSettings,
  onSkipToFreeTrial,
}) => {
  const intl = useIntl()

  const { control, handleSubmit, setValue, watch } = useFixedForm<CreditSettingsFormValues>({
    resolver: yupResolver(useCreditSettingsFormValidation()),
    onSubmit: (values) => onConfigureCreditSettings(values),
    defaultValues: {
      automaticRecharge: creditSettingsDefaultValues?.automaticRecharge ?? false,
      initialCredit: creditSettingsDefaultValues?.initialCredit ?? 'other',
      automaticRechargeAmount: creditSettingsDefaultValues?.automaticRechargeAmount,
      automaticRechargeThreshold: creditSettingsDefaultValues?.automaticRechargeThreshold,
    },
  })

  const autoRechargeEnabled = watch('automaticRecharge')
  const initialCredit = watch('initialCredit')
  const isSkipToFreeTrialPath =
    initialCredit === 'other' || initialCredit === '' || initialCredit === '0' || initialCredit === 0
  const handleCreditAmountSelection = useCallback(
    (value?: string) => {
      if (value === 'other') {
        setValue('initialCredit', '')
      } else {
        const numericValue = value ? Number(value) : 0
        setValue('initialCredit', isNaN(numericValue) ? 0 : numericValue)
      }
    },
    [setValue],
  )

  useEffect(() => {
    if (creditSettingsDefaultValues) {
      handleCreditAmountSelection(creditSettingsDefaultValues?.initialCredit?.toString())
    }
  }, [creditSettingsDefaultValues, handleCreditAmountSelection])

  const radioCardOptions = [
    {
      content: (
        <p className="text-center font-bold text-green-80">
          {intl.formatMessage(ConfigureCreditSettingsMessages.creditOption5)}
        </p>
      ),
      value: 5,
    },
    {
      content: (
        <p className="text-center font-bold text-green-80">
          {intl.formatMessage(ConfigureCreditSettingsMessages.creditOption10)}
        </p>
      ),
      value: 10,
    },
    {
      content: (
        <p className="text-center font-bold text-green-80">
          {intl.formatMessage(ConfigureCreditSettingsMessages.creditOption20)}
        </p>
      ),
      value: 20,
    },
    {
      content: (
        <p className="text-center font-bold text-green-80">
          {intl.formatMessage(ConfigureCreditSettingsMessages.creditOptionOther)}
        </p>
      ),
      value: 'other',
    },
  ]

  return (
    <div className="relative h-screen w-full">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="w-full max-w-2xl">
          <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(ConfigureCreditSettingsMessages.title)}</h1>
          <p className="mb-10 text-base">
            {intl.formatMessage(ConfigureCreditSettingsMessages.description, {
              learn_more_link: (
                <Link url="https://docs.salad.com/products/sce/billing">
                  {intl.formatMessage(ConfigureCreditSettingsMessages.learnMoreLink)}
                </Link>
              ),
            })}
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-8">
              <Controller
                name="initialCredit"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <div className="mb-4 flex gap-4">
                      <RadioCards
                        cards={radioCardOptions}
                        defaultCheckedValue={
                          radioCardOptions.some((option) => option.value === field.value) ? field.value : 'other'
                        }
                        hasNoMarginBottom
                        isInline
                        label={intl.formatMessage(ConfigureCreditSettingsMessages.creditAmountLabel)}
                        name="initialCredit"
                        onChange={(value) => handleCreditAmountSelection(value)}
                      />
                    </div>
                    <div className="mb-4 flex gap-4">
                      <TextField
                        {...field}
                        {...fieldState}
                        defaultValue={field.value}
                        hasNoDefaultHeight
                        hasNoDefaultMarginBottom
                        helperText={
                          fieldState.error?.message ??
                          intl.formatMessage(ConfigureCreditSettingsMessages.customAmountHelperText)
                        }
                        hideLabelText
                        isFullWidth
                        label={intl.formatMessage(ConfigureCreditSettingsMessages.customAmountLabel)}
                        leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                        onChange={(value) => field.onChange(value)}
                        placeholder={initialCredit !== 'other' ? initialCredit : undefined}
                        type="number"
                      />
                    </div>
                  </>
                )}
              />
            </div>

            <h2 className="mb-4 text-xl font-bold">
              {intl.formatMessage(ConfigureCreditSettingsMessages.autoRechargeTitle)}
            </h2>

            <div className="mb-8">
              <Controller
                name="automaticRecharge"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    isChecked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                    label={intl.formatMessage(ConfigureCreditSettingsMessages.autoRechargeToggleLabel)}
                    name="automaticRecharge"
                    id="automaticRecharge"
                  />
                )}
              />
            </div>

            <Controller
              name="automaticRechargeThreshold"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  defaultValue={field.value}
                  helperText={
                    fieldState.error?.message ??
                    intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdHelperText)
                  }
                  isDisabled={!autoRechargeEnabled}
                  isFullWidth
                  label={intl.formatMessage(ConfigureCreditSettingsMessages.rechargeThresholdLabel)}
                  leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                  type="number"
                />
              )}
            />
            <Controller
              name="automaticRechargeAmount"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  defaultValue={field.value}
                  helperText={intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountHelperText)}
                  isDisabled={!autoRechargeEnabled}
                  isFullWidth
                  label={intl.formatMessage(ConfigureCreditSettingsMessages.rechargeAmountLabel)}
                  leftIconClassName="fa-solid fa-dollar-sign text-neutral-90"
                  type="number"
                />
              )}
            />

            <div className="mt-6 flex w-full gap-12">
              <div className="flex-1">
                <Button variant="green-outlined" type="button" onClick={onBack} isFullWidth>
                  {intl.formatMessage(ConfigureCreditSettingsMessages.backLabel)}
                </Button>
              </div>

              <div className="flex-1">
                {isSkipToFreeTrialPath ? (
                  <Button isFullWidth isLoading={isSkipToFreeTrialPending} onClick={onSkipToFreeTrial} type="button">
                    {intl.formatMessage(ConfigureCreditSettingsMessages.skipToFreeTrialButtonLabel)}
                  </Button>
                ) : (
                  <Button type="submit" isLoading={isConfigureCreditSettingsPending} isFullWidth>
                    {intl.formatMessage(ConfigureCreditSettingsMessages.continueLabel)}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
