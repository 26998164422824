import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  CreateOrganizationWizardSetupIntent,
  CreateOrganizationWizardState,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { CreateOrganizationWizardPageSteps } from '../../pages/CreateOrganizationWizard/models'

export type CreateOrganizationWizardProps = {
  createOrganizationWizard: CreateOrganizationWizardState
  createOrganizationWizardSetupIntent?: CreateOrganizationWizardSetupIntent
}

export const createOrganizationWizardAdapter = createEntityAdapter<CreateOrganizationWizardProps, string>({
  selectId: (item) => item.createOrganizationWizard.id,
  sortComparer: (a, b) => a.createOrganizationWizard.id.localeCompare(b.createOrganizationWizard.id),
})

export const createOrganizationWizardSlice = createSlice({
  name: 'createOrganizationWizardSlice',
  initialState: createOrganizationWizardAdapter.getInitialState(),
  reducers: {
    checkOrganizationNameAvailability(_state, _action: PayloadAction<{ organizationName: string }>) {},
    confirmPaymentSummary(
      _state,
      _action: PayloadAction<{ organizationReservedName: string; createOrganizationWizardId: string }>,
    ) {},
    createCreateOrganizationWizard() {},
    createOrganizationWizardAdded: createOrganizationWizardAdapter.addOne,
    createOrganizationWizardRemoved: createOrganizationWizardAdapter.removeOne,
    createOrganizationWizardSetupIntent: (
      state,
      action: PayloadAction<{ id: string; setupIntent: CreateOrganizationWizardSetupIntent }>,
    ) => {
      const entity = state.entities[action.payload.id]
      if (entity) {
        entity.createOrganizationWizardSetupIntent = action.payload.setupIntent
      }
    },
    createOrganizationWizardsReceived(
      state,
      action: PayloadAction<{ createOrganizationWizards: CreateOrganizationWizardState[] }>,
    ) {
      const formattedWizards = action.payload.createOrganizationWizards.map((wizard) => ({
        createOrganizationWizard: wizard,
      }))
      createOrganizationWizardAdapter.setAll(state, formattedWizards)
    },
    listCreateOrganizationWizards() {},

    deleteCreateOrganizationWizard(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
    editCreateOrganizationWizard(
      _state,
      _action: PayloadAction<{
        createOrganizationWizard: CreateOrganizationWizardState
        currentRoute: CreateOrganizationWizardPageSteps
      }>,
    ) {},
    getAddPaymentMethodPageData(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
    getAddPaymentMethodSetupStatus(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
    getCreateOrganizationWizard(_state, _action: PayloadAction<{ createOrganizationWizardId: string }>) {},
    stopPollingForCreateOrganizationWizardSetupIntent(
      _state,
      _action: PayloadAction<{ createOrganizationWizardId: string }>,
    ) {},
    stopPollingForCreateOrganizationWizardSubmission(
      _state,
      _action: PayloadAction<{ createOrganizationWizardId: string }>,
    ) {},
  },
})

export const {
  checkOrganizationNameAvailability,
  confirmPaymentSummary,
  createCreateOrganizationWizard,
  createOrganizationWizardAdded,
  createOrganizationWizardRemoved,
  createOrganizationWizardSetupIntent,
  createOrganizationWizardsReceived,
  deleteCreateOrganizationWizard,
  editCreateOrganizationWizard,
  getAddPaymentMethodPageData,
  getAddPaymentMethodSetupStatus,
  getCreateOrganizationWizard,
  listCreateOrganizationWizards,
  stopPollingForCreateOrganizationWizardSetupIntent,
  stopPollingForCreateOrganizationWizardSubmission,
} = createOrganizationWizardSlice.actions
