import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { LoadingPage } from '../../../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../../../features/analytics/analyticsSlice'
import {
  checkOrganizationNameAvailability,
  editCreateOrganizationWizard,
  getCreateOrganizationWizard,
} from '../../../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectIsLeftColumnShowing } from '../../../../features/navigationBar/navigationBarSelectors'
import { setIsOrganizationNameAvailable } from '../../../../features/organizationInformation/organizationInformation'
import { selectIsOrganizationNameAvailable } from '../../../../features/organizationInformation/organizationInformationSelectors'
import { selectRequestStatus } from '../../../../features/requestStatus/requestStatusSelectors'
import { setLeftColumnShowing } from '../../../../mockFeatures/mockNavigationBar/mockNavigationBarSlice'
import { CreateOrganizationWizardPath } from '../../../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { CreateOrganizationWizardPageSteps } from '../../models'
import { OrganizationInformationPage } from './OrganizationInformationPage'

export const ConnectedOrganizationInformationPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const dispatch = useAppDispatch()
  const isOrganizationNameAvailable = useAppSelector((state) => selectIsOrganizationNameAvailable(state))
  const isCheckOrganizationNameAvailabilityPending = useAppSelector(
    (state) => selectRequestStatus(state, 'checkOrganizationNameAvailability') === 'pending',
  )
  const isReserveOrganizationNamePending = useAppSelector(
    (state) => selectRequestStatus(state, 'editCreateOrganizationWizard') === 'pending',
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isGetOrganizationWizardPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getCreateOrganizationWizard') === 'pending',
  )

  const onCheckOrganizationNameAvailability = useCallback(
    (organizationName: string) => {
      dispatch(checkOrganizationNameAvailability({ organizationName }))
    },
    [dispatch],
  )

  const onReserveOrganizationName = useCallback(
    (organizationName: string) =>
      dispatch(
        editCreateOrganizationWizard({
          createOrganizationWizard: {
            id: createOrganizationWizardId,
            organizationReservedName: organizationName,
          },
          currentRoute: CreateOrganizationWizardPageSteps.OrganizationInformation,
        }),
      ),
    [dispatch, createOrganizationWizardId],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleTrackTurnstileMixpanelEvent = useCallback(
    (eventName: string, turnstileErrorCode?: string) => {
      dispatch(
        trackMixpanelEvent({
          event: eventName,
          properties: { path: CreateOrganizationWizardPath.OrganizationInformation, turnstileErrorCode },
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(getCreateOrganizationWizard({ createOrganizationWizardId }))
    dispatch(
      setIsOrganizationNameAvailable({
        isOrganizationNameAvailable: undefined,
      }),
    )
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: CreateOrganizationWizardPath.OrganizationInformation },
      }),
    )
  }, [createOrganizationWizardId, dispatch])

  return isGetOrganizationWizardPending ? (
    <LoadingPage />
  ) : (
    <OrganizationInformationPage
      isCheckOrganizationNameAvailabilityPending={isCheckOrganizationNameAvailabilityPending}
      isLeftColumnOpen={isLeftColumnOpen}
      isOrganizationNameAvailable={isOrganizationNameAvailable}
      isReserveOrganizationNamePending={isReserveOrganizationNamePending}
      onCheckOrganizationNameAvailability={onCheckOrganizationNameAvailability}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onReserveOrganizationName={onReserveOrganizationName}
      onTrackTurnstileMixpanelEvent={handleTrackTurnstileMixpanelEvent}
    />
  )
}
