import {
  CreateOrganizationProblemType,
  CreateOrganizationWizardStateStateEnum,
  CreateProjectProblemType,
  ResponseError,
  UpdateCreateOrganizationWizardProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  Subject,
  catchError,
  concat,
  delay,
  filter,
  from,
  mergeMap,
  of,
  repeat,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs'
import { CreateOrganizationWizardAPI, OrganizationNameAvailabilityAPI, assertUnreachable } from '../apiMethods'
import {
  checkOrganizationNameAvailability,
  confirmPaymentSummary,
  createCreateOrganizationWizard,
  createOrganizationWizardAdded,
  createOrganizationWizardRemoved,
  createOrganizationWizardSetupIntent,
  deleteCreateOrganizationWizard,
  editCreateOrganizationWizard,
  getAddPaymentMethodPageData,
  getAddPaymentMethodSetupStatus,
  getCreateOrganizationWizard,
  stopPollingForCreateOrganizationWizardSetupIntent,
  stopPollingForCreateOrganizationWizardSubmission,
} from '../features/createOrganizationWizard/createOrganizationWizardSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setIsOrganizationNameAvailable } from '../features/organizationInformation/organizationInformation'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getAddNewPaymentMethodSucceeded } from '../notifications/clientToastNotificationContent/billing/getAddNewPaymentMethodSucceeded'
import {
  getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeAmountErrorContent,
  getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeThresholdErrorContent,
  getCreateOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContent,
  getCreateOrganizationWizardCreateOrganizationGeneralErrorContent,
  getCreateOrganizationWizardOrganizationNonUniqueNameContent,
  getUnableToContinueInCreateOrganizationWizardGenericErrorContent,
} from '../notifications/clientToastNotificationContent/createOrganizationWizard'
import { getCreateOrganizationWizardGeneralErrorContent } from '../notifications/clientToastNotificationContent/createOrganizationWizard/getCreateOrganizationWizardGeneralErrorContent'
import {
  getCreateOrganizationGeneralErrorContent,
  getCreateOrganizationWithAlreadyUsedReferralCodeContent,
  getCreateOrganizationWithInvalidReferralCodeContent,
  getCreateOrganizationWithNonUniqueNameContent,
} from '../notifications/clientToastNotificationContent/organizations'
import {
  getCreateProjectGeneralErrorContent,
  getCreateProjectWithNonUniqueNameContent,
} from '../notifications/clientToastNotificationContent/projects'
import { CreateOrganizationWizardPageSteps } from '../pages/CreateOrganizationWizard/models'
import {
  getCreateOrganizationWizardAddPaymentMethodPagePath,
  getCreateOrganizationWizardConfigureCreditSettingsPagePath,
  getCreateOrganizationWizardInviteTeamMembersPagePath,
  getCreateOrganizationWizardOrganizationInformationPagePath,
  getCreateOrganizationWizardPaymentMethodSummaryPagePath,
} from '../routes/routes-utils'
import { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onCheckOrganizationNameAvailability: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(checkOrganizationNameAvailability.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'pending' })),
        from(
          OrganizationNameAvailabilityAPI.organizationNameAvailability({
            checkOrganizationNameAvailability: { name: organizationName },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'succeeded' }),
                setIsOrganizationNameAvailable({ isOrganizationNameAvailable: true }),
              ),
              of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() => {
            return concat(
              of(
                setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'failed' }),
                setIsOrganizationNameAvailable({ isOrganizationNameAvailable: false }),
              ),
              of(setRequestStatus({ request: 'checkOrganizationNameAvailability', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )

export const onCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createCreateOrganizationWizard.match),
    mergeMap(() => {
      return concat(
        of(
          setRequestStatus({
            request: 'createOrganizationWizard',
            status: 'pending',
          }),
        ),
        from(CreateOrganizationWizardAPI.createOrganizationWizard()).pipe(
          mergeMap((createOrganizationWizard) =>
            concat(
              of(
                createOrganizationWizardAdded({ createOrganizationWizard }),
                setRequestStatus({
                  request: 'createOrganizationWizard',
                  status: 'succeeded',
                }),
                navigateTo({
                  path: getCreateOrganizationWizardOrganizationInformationPagePath(createOrganizationWizard.id),
                }),
              ),
              of(
                setRequestStatus({
                  request: 'createOrganizationWizard',
                  status: 'idle',
                }),
              ).pipe(delay(1)),
            ).pipe(
              catchError(() => {
                return concat(
                  of(
                    setRequestStatus({
                      request: 'createOrganizationWizard',
                      status: 'failed',
                    }),
                    showToastNotification(getCreateOrganizationWizardCreateOrganizationGeneralErrorContent(intl)),
                  ),
                  of(
                    setRequestStatus({
                      request: 'createOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              }),
            ),
          ),
        ),
      )
    }),
  )

export const onDeleteCreateOrganizationWizard: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(deleteCreateOrganizationWizard.match),
    mergeMap(({ payload: { createOrganizationWizardId } }) =>
      concat(
        of(setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'pending' })),
        from(CreateOrganizationWizardAPI.deleteOrganizationWizard({ createOrganizationWizardId })).pipe(
          mergeMap(() =>
            concat(
              of(
                createOrganizationWizardRemoved(createOrganizationWizardId),
                setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'failed' })),
              of(setRequestStatus({ request: 'deleteCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onEditCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editCreateOrganizationWizard.match),
    mergeMap(({ payload: { createOrganizationWizard, currentRoute } }) =>
      concat(
        of(
          setRequestStatus({
            request: 'editCreateOrganizationWizard',
            status: 'pending',
          }),
        ),
        from(
          CreateOrganizationWizardAPI.updateOrganizationWizard({
            createOrganizationWizardId: createOrganizationWizard.id,
            updateCreateOrganizationWizard: createOrganizationWizard,
          }),
        ).pipe(
          mergeMap(() => {
            switch (currentRoute) {
              case CreateOrganizationWizardPageSteps.OrganizationInformation:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path: getCreateOrganizationWizardConfigureCreditSettingsPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              case CreateOrganizationWizardPageSteps.ConfigureCreditSettings:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path: getCreateOrganizationWizardAddPaymentMethodPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )

              case CreateOrganizationWizardPageSteps.PaymentSummary:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path: getCreateOrganizationWizardInviteTeamMembersPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              case CreateOrganizationWizardPageSteps.AddPaymentMethod:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                    navigateTo({
                      path:
                        createOrganizationWizard.initialCredit === 0
                          ? getCreateOrganizationWizardInviteTeamMembersPagePath(createOrganizationWizard.id)
                          : getCreateOrganizationWizardPaymentMethodSummaryPagePath(createOrganizationWizard.id),
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
              default:
                return concat(
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'succeeded',
                    }),
                  ),
                  of(
                    setRequestStatus({
                      request: 'editCreateOrganizationWizard',
                      status: 'idle',
                    }),
                  ).pipe(delay(1)),
                )
            }
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as UpdateCreateOrganizationWizardProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case UpdateCreateOrganizationWizardProblemType.CreateOrganizationInvalidAutomaticRechargeThresholdError:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeThresholdErrorContent(
                                  intl,
                                ),
                              ),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.CreateOrganizationInvalidInitialCreditError:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWizardConfigureCreditSettingsInvalidInitialCreditErrorContent(
                                  intl,
                                ),
                              ),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.CreateOrganizationWizardInvalidAutomaticRechargeAmount:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWizardConfigureCreditSettingsInvalidAutomaticRechargeAmountErrorContent(
                                  intl,
                                ),
                              ),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.OrganizationNameAlreadyReserved:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(getCreateOrganizationWizardOrganizationNonUniqueNameContent(intl)),
                            ),
                          )
                        case UpdateCreateOrganizationWizardProblemType.UnexpectedError:
                          return concat(
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                            of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl),
                              ),
                            ),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                        of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(
                          showToastNotification(getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl)),
                        ),
                      )
                    }
                  }),
                )
              : concat(
                  of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'failed' })),
                  of(setRequestStatus({ request: 'editCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
                  of(showToastNotification(getUnableToContinueInCreateOrganizationWizardGenericErrorContent(intl))),
                )
          }),
        ),
      ),
    ),
  )

export const onGetCreateOrganizationWizard: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getCreateOrganizationWizard.match),
    mergeMap(({ payload: { createOrganizationWizardId } }) =>
      concat(
        of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'pending' })),
        from(CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId })).pipe(
          mergeMap((createOrganizationWizard) => {
            return concat(
              of(
                createOrganizationWizardAdded({ createOrganizationWizard }),
                setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'failed' })),
              of(setRequestStatus({ request: 'getCreateOrganizationWizard', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getCreateOrganizationWizardGeneralErrorContent(intl))),
            ),
          ),
        ),
      ),
    ),
  )

const pollOrganizationWizardSubmissionStatusUtil = (createOrganizationWizardId: string, stopPolling$: Subject<void>) =>
  of(null).pipe(
    switchMap(() =>
      from(CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId })).pipe(
        switchMap((createOrganizationWizard) => {
          const { state } = createOrganizationWizard

          const shouldStopPolling = state === CreateOrganizationWizardStateStateEnum.Completed

          if (shouldStopPolling) {
            stopPolling$.next()
          }

          return of(createOrganizationWizardAdded({ createOrganizationWizard }))
        }),
      ),
    ),
    repeat({ delay: () => timer(1000) }),
    takeUntil(stopPolling$),
  )

export const onConfirmPaymentSummary: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(confirmPaymentSummary.match),
    mergeMap(({ payload: { createOrganizationWizardId, organizationReservedName } }) => {
      const stopPolling$ = new Subject<void>()

      return concat(
        of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'pending' })),
        from(
          /* By sending an empty object to the updateOrganizationWizard method, we are signaling to the backend that the user has confirmed the payment summary.
          After we need to poll the backend to check if the organization creation has been completed */
          CreateOrganizationWizardAPI.updateOrganizationWizard({
            createOrganizationWizardId,
            updateCreateOrganizationWizard: {},
          }),
        ).pipe(
          mergeMap(() =>
            pollOrganizationWizardSubmissionStatusUtil(createOrganizationWizardId, stopPolling$).pipe(
              takeUntil(action$.pipe(filter(stopPollingForCreateOrganizationWizardSubmission.match))),
              mergeMap(() =>
                concat(
                  of(
                    setRequestStatus({ request: 'confirmPaymentSummary', status: 'succeeded' }),
                    navigateTo({
                      path: getCreateOrganizationWizardInviteTeamMembersPagePath(createOrganizationWizardId),
                    }),
                  ),
                  of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                ),
              ),
              catchError((error: unknown) =>
                error instanceof ResponseError && error.response.status === 400
                  ? from(error.response.json()).pipe(
                      mergeMap((errorResponse) => {
                        const errorType = errorResponse.type as CreateProjectProblemType | null
                        if (errorType != null) {
                          switch (errorType) {
                            case CreateProjectProblemType.NameConflict:
                              return concat(
                                of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' })),
                                of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(
                                  delay(1),
                                ),
                                of(showToastNotification(getCreateProjectWithNonUniqueNameContent(intl, 'default'))),
                              )
                            default:
                              assertUnreachable(errorType)
                          }
                        } else {
                          return concat(
                            of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' })),
                            of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                            of(showToastNotification(getCreateProjectGeneralErrorContent(intl))),
                          )
                        }
                      }),
                    )
                  : concat(
                      of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'failed' })),
                      of(setRequestStatus({ request: 'confirmPaymentSummary', status: 'idle' })).pipe(delay(1)),
                      of(showToastNotification(getCreateProjectGeneralErrorContent(intl))),
                    ),
              ),
            ),
          ),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateOrganizationProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case CreateOrganizationProblemType.InvalidReferralCode:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationWithInvalidReferralCodeContent(intl))),
                          )
                        case CreateOrganizationProblemType.NameConflict:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(
                              showToastNotification(
                                getCreateOrganizationWithNonUniqueNameContent(intl, organizationReservedName),
                              ),
                            ),
                          )
                        case CreateOrganizationProblemType.ReferralCodeAlreadyUsed:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationWithAlreadyUsedReferralCodeContent(intl))),
                          )
                        case CreateOrganizationProblemType.ReferralCodeExpired:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationWithInvalidReferralCodeContent(intl))),
                          )
                        case CreateOrganizationProblemType.ReferralCodeInvalidEmail:
                          return concat(
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                            of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                              delay(1),
                            ),
                            of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                        of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(
                          delay(1),
                        ),
                        of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                      )
                    }
                  }),
                )
              : concat(
                  of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'failed' })),
                  of(setRequestStatus({ request: 'createOrganizationAndProject', status: 'idle' })).pipe(delay(1)),
                  of(showToastNotification(getCreateOrganizationGeneralErrorContent(intl))),
                ),
          ),
        ),
      )
    }),
  )

export const onGetAddPaymentMethodPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getAddPaymentMethodPageData.match),
    mergeMap(({ payload: { createOrganizationWizardId } }) =>
      concat(
        of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'pending' })),
        from(
          Promise.all([
            CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId }),
            CreateOrganizationWizardAPI.createOrganizationWizardSetupIntents({ createOrganizationWizardId }),
          ]),
        ).pipe(
          mergeMap(([createOrganizationWizard, setupIntentResponse]) =>
            concat(
              of(
                createOrganizationWizardAdded({ createOrganizationWizard }),
                createOrganizationWizardSetupIntent({
                  id: createOrganizationWizardId,
                  setupIntent: setupIntentResponse,
                }),
                setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'failed' })),
              of(setRequestStatus({ request: 'getAddPaymentMethodPageData', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getCreateOrganizationWizardGeneralErrorContent(intl))),
            ),
          ),
        ),
      ),
    ),
  )

const pollOrganizationWizardSetupIntentUtil = (createOrganizationWizardId: string, stopPolling$: Subject<void>) =>
  of(null).pipe(
    switchMap(() =>
      from(CreateOrganizationWizardAPI.getOrganizationWizard({ createOrganizationWizardId })).pipe(
        switchMap((createOrganizationWizard) => {
          const { state } = createOrganizationWizard

          const shouldStopPolling =
            state === CreateOrganizationWizardStateStateEnum.SetupIntentFailed ||
            state === CreateOrganizationWizardStateStateEnum.ReadyForSubmission

          if (shouldStopPolling) {
            stopPolling$.next()
            stopPolling$.complete()
          }

          return of(createOrganizationWizardAdded({ createOrganizationWizard }))
        }),
      ),
    ),
    repeat({ delay: () => timer(1000) }),
    takeUntil(stopPolling$),
  )

export const onGetAddPaymentMethodSetupStatus: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getAddPaymentMethodSetupStatus.match),
    mergeMap(({ payload: { createOrganizationWizardId } }) => {
      const stopPolling$ = new Subject<void>()

      return concat(
        of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'pending' })),
        pollOrganizationWizardSetupIntentUtil(createOrganizationWizardId, stopPolling$).pipe(
          switchMap((pollingAction) => {
            console.log('pollingAction', pollingAction)
            const updatedState = pollingAction.payload.createOrganizationWizard.state

            if (updatedState === CreateOrganizationWizardStateStateEnum.ReadyForSubmission) {
              console.log('updatedState', updatedState)
              return concat(
                of(pollingAction),
                of(showToastNotification(getAddNewPaymentMethodSucceeded(intl))),
                of(
                  navigateTo({
                    path: getCreateOrganizationWizardPaymentMethodSummaryPagePath(createOrganizationWizardId),
                  }),
                ),
                of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'succeeded' })),
              )
            }

            return of(pollingAction)
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'failed' })),
              of(showToastNotification(getCreateOrganizationWizardGeneralErrorContent(intl))),
              of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })),
            ),
          ),
        ),
        of(setRequestStatus({ request: 'getAddPaymentMethodSetupStatus', status: 'idle' })).pipe(delay(1)),
      ).pipe(takeUntil(action$.pipe(filter(stopPollingForCreateOrganizationWizardSetupIntent.match))))
    }),
  )
