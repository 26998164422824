import { FunctionComponent, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LoadingPage } from '../../../../components/page/LoadingPage'
import { trackMixpanelEvent } from '../../../../features/analytics/analyticsSlice'
import { showStripeFormError } from '../../../../features/billing/billingSlice'
import {
  selectCreateOrganizationWizard,
  selectCreateOrganizationWizardSetupIntent,
} from '../../../../features/createOrganizationWizard/createOrganizationWizardSelector'
import {
  getAddPaymentMethodPageData,
  getAddPaymentMethodSetupStatus,
  stopPollingForCreateOrganizationWizardSetupIntent,
} from '../../../../features/createOrganizationWizard/createOrganizationWizardSlice'
import { selectIsLeftColumnShowing } from '../../../../features/navigationBar/navigationBarSelectors'
import { selectRequestStatus } from '../../../../features/requestStatus/requestStatusSelectors'
import { setLeftColumnShowing } from '../../../../mockFeatures/mockNavigationBar/mockNavigationBarSlice'
import { CreateOrganizationWizardPath } from '../../../../routes/routePaths'
import { getCreateOrganizationWizardConfigureCreditSettingsPagePath } from '../../../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { AddPaymentMethodPage } from './AddPaymentMethodPage'

export const ConnectedAddPaymentMethodPage: FunctionComponent = () => {
  const { createOrganizationWizardId = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isGetAddPaymentMethodSetupStatusPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getAddPaymentMethodSetupStatus') === 'pending',
  )
  const isGetAddPaymentMethodPageDataPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getAddPaymentMethodPageData') === 'pending',
  )
  const createOrganizationWizardSetupIntent = useAppSelector((state) =>
    selectCreateOrganizationWizardSetupIntent(state, createOrganizationWizardId),
  )
  const clientSecret = createOrganizationWizardSetupIntent?.clientSecret
  const createOrganizationWizard = useAppSelector((state) =>
    selectCreateOrganizationWizard(state, createOrganizationWizardId),
  )
  const isFreeTrialEnabled = createOrganizationWizard?.createOrganizationWizard?.initialCredit === 0

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleShowStripeFormError = useCallback(
    (message?: string, status?: string) => {
      dispatch(showStripeFormError({ message, status }))
    },
    [dispatch],
  )

  const handleOnSubmitStripeFormSucceeded = useCallback(() => {
    if (createOrganizationWizardId) {
      dispatch(getAddPaymentMethodSetupStatus({ createOrganizationWizardId }))
    }
  }, [dispatch, createOrganizationWizardId])

  const handleOnBack = useCallback(() => {
    navigate(getCreateOrganizationWizardConfigureCreditSettingsPagePath(createOrganizationWizardId))
  }, [createOrganizationWizardId, navigate])

  useEffect(() => {
    dispatch(getAddPaymentMethodPageData({ createOrganizationWizardId }))
    dispatch(
      trackMixpanelEvent({
        event: 'Page Viewed',
        properties: { path: CreateOrganizationWizardPath.AddPaymentMethod, isFreeTrialEnabled },
      }),
    )
  }, [dispatch, createOrganizationWizardId, isFreeTrialEnabled])

  useEffect(() => {
    return () => {
      dispatch(stopPollingForCreateOrganizationWizardSetupIntent({ createOrganizationWizardId }))
    }
  }, [createOrganizationWizardId, dispatch])

  return isGetAddPaymentMethodPageDataPending ? (
    <LoadingPage />
  ) : (
    <AddPaymentMethodPage
      isGetAddPaymentMethodSetupStatusPending={isGetAddPaymentMethodSetupStatusPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onSubmitStripeFormError={handleShowStripeFormError}
      onSubmitStripeFormSucceeded={handleOnSubmitStripeFormSucceeded}
      onBack={handleOnBack}
      isFreeTrial={isFreeTrialEnabled}
      clientSecret={clientSecret}
    />
  )
}
